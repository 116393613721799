import { useTranslation } from 'react-i18next';
import {
  Attribute,
  VariantFormValues,
  VariantsOptionsCombineList,
} from 'src/ts-types/custom.types';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from '../common/card';
import UnControlledFileInput from '../ui/unControlled-file-input';
import { UPLOAD_SECTION } from 'src/ts-types';
import UnControlledSelectInput from '../ui/unControlled-select-input';
import Label from '../ui/label';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { setProductCurrentVariant, setProductVariant } from 'src/store/shop/actions';
import Input from '../ui/input';
import InputWithTranslation from '../ui/inputWithTranslation';
import { ATTRIBUTES } from 'src/utils/variants';
import { RootState } from 'src/store';
import { useModalAction, useModalState } from '../ui/modal/modal.context';
import { FormProvider, useForm } from 'react-hook-form';
import { variantValidationSchema } from './variant-validation-schema';
import Button from '../ui/button';
import { useEffect, useState } from 'react';
import { DatePicker } from '../ui/date-picker';
import { formatDate, isValidDiscountDate } from 'src/common';
import SwitchInput from '../ui/switch-input';
import {
  getCurrentDate,
  getVariantDiscountPrice,
  isProductHasSaleDiscount,
} from 'src/utils/productUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const getVariantIds = () => {
  const firstLevelArray = ATTRIBUTES.map((attribute: Attribute) => ({
    optionValueId: attribute.optionNameId,
    optionValue: attribute.name,
  }));
  return firstLevelArray;
};

const VariantComponent = () => {
  const { t }: any = useTranslation<any>();
  const [variantId, setVariantId] = useState<string>();
  const dispatch = useAppDispatch();
  const modalData = useModalState();
  const { closeModal } = useModalAction();
  const { data } = modalData;
  const { isEditMode } = data;
  const activeProduct = useAppSelector((state: RootState) => state.shop.shopDetails?.activeProduct);
  const currentVariant = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.currentVariant,
  );
  const productVariants = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.productVariants,
  );
  console.log('isEditMode', isEditMode);
  const [isOutOdStock, setIsOutOfStock] = useState(currentVariant?.countInStock === 0);

  const methods = useForm<VariantFormValues>({
    resolver: yupResolver(variantValidationSchema),
    mode: 'onBlur',
    defaultValues: {
      id: currentVariant?.id,
      active: currentVariant?.active,
      mainVariant: currentVariant?.mainVariant,
      productMilli: currentVariant?.productMilli,
      nameWithTranslation: currentVariant?.nameWithTranslation,
      productCode: currentVariant?.productCode,
      productSEO: currentVariant?.productSEO,
      price: currentVariant?.price,
      countInStock: currentVariant?.countInStock,
      discountPrice: getVariantDiscountPrice(currentVariant, activeProduct!),
      mainImage: currentVariant?.mainImage,
      moreImages: currentVariant?.moreImages,
      thumbnailImage: currentVariant?.thumbnailImage,
      endDiscountDate: currentVariant?.endDiscountDate,
      variantsOptionsCombineList: currentVariant?.variantsOptionsCombineList,
      variantMetaText: currentVariant?.variantMetaText,
      variantMeta: currentVariant?.variantMeta,
      variantTypeId: currentVariant?.variantTypeId,
    },
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    register,
    watch,
    formState: { errors },
  } = methods;

  const [date, setDate] = useState<number | null>(() => {
    const categoryEndDiscountDate = activeProduct?.categoryEndDiscountDate;
    const variantDiscountDate = getValues('endDiscountDate');
    const isVariantDiscountDateValid = isValidDiscountDate(variantDiscountDate);
    const isCategoryDiscountDateValid = isValidDiscountDate(categoryEndDiscountDate);

    let variantEndDiscountDateRaw = null;
    if (isVariantDiscountDateValid) {
      variantEndDiscountDateRaw = variantDiscountDate;
    } else if (isCategoryDiscountDateValid) {
      variantEndDiscountDateRaw = categoryEndDiscountDate;
    }

    const endDiscountDate = variantEndDiscountDateRaw
      ? new Date(variantEndDiscountDateRaw).getTime()
      : null;

    const adjustedDate = getCurrentDate(endDiscountDate);
    return adjustedDate;
  });

  const handleMetaAddImage = (value: string) => {
    const variantsOptionsCombineList: VariantsOptionsCombineList[] = [
      {
        optionNameId: getValues('variantTypeId'),
        optionValueId: value,
      },
    ];
    setValue('variantMeta', value);
    setValue('variantsOptionsCombineList', variantsOptionsCombineList);
  };

  useEffect(() => {
    const variant = getValues('variantTypeId');
    setVariantId(variant);
  }, [getValues('variantTypeId')]);

  const handleVariantId = (val: any) => {
    setValue('variantTypeId', val.optionValueId);
  };

  const getVariantTypeValue = () => {
    return currentVariant?.variantsOptionsCombineList?.[0]?.optionNameId ?? '';
  };

  const onSubmit = async (values: VariantFormValues) => {
    const transformedValues = {
      ...values,
      discountPrice: values.discountPrice ? values.price - values.discountPrice : 0, // Set discountPrice to 0 if null, undefined, or 0
    };
    if (isEditMode) {
      const updatedVariants = productVariants?.map?.((variant) => {
        if (variant.id === transformedValues.id) {
          return {
            ...transformedValues,
          };
        }
        return variant;
      });
      dispatch(setProductVariant(updatedVariants));
    } else {
      dispatch(setProductCurrentVariant(transformedValues));
      if (productVariants && productVariants.length > 0) {
        dispatch(setProductVariant([...productVariants, transformedValues]));
      } else {
        dispatch(setProductVariant([transformedValues]));
      }
    }
    closeModal();
  };

  const onChangeDate = (value: Date) => {
    if (value) {
      const utcDate = new Date(Date.UTC(value?.getFullYear(), value?.getMonth(), value?.getDate()));
      setDate(utcDate?.getTime());
      setValue('endDiscountDate', formatDate(utcDate?.toISOString()));
    } else {
      setDate(null);
      setValue('endDiscountDate', undefined);
    }
  };

  const variantTypeId = watch('variantTypeId');

  const handleOutOfStock = (val: boolean) => {
    const countInStock = val ? 0 : 100;
    setValue('countInStock', countInStock);
    setIsOutOfStock(val);
  };
  const _isProductHasSaleDiscount = isProductHasSaleDiscount(activeProduct);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className='p-4 pb-6 bg-light m-auto w-full max-w-[500px]'>
          <Card
            key={currentVariant?.id}
            withShadow={false}
            className='border-accent-200 rounded-lg mt-6'
          >
            <>
              <div className='w-full'>
                <div className='flex'>
                  {/* <SwitchInput
                    label={t('input-label-active-product')}
                    name={'active'}
                    className='pb-2 pl-6'
                    onChange={(val) => handleVariantStatusChange(idx, 'active', val)}
                    defaultValue={variantStatus?.[idx]?.active ?? false}
                    value={variantStatus?.[idx]?.active ?? false}
                  /> */}
                  <SwitchInput
                    label={t('text-out-stock')}
                    className='pb-2'
                    name={'countInStock'}
                    onChange={handleOutOfStock}
                    defaultValue={isOutOdStock}
                    value={isOutOdStock}
                  />
                </div>
                <div>
                  <div className='flex flex-col md:flex-row md:space-x-4'>
                    <InputWithTranslation
                      label={`${t('input-label-name')}`}
                      values={getValues('nameWithTranslation')}
                      containerClassName='step3prod ml-6'
                      setValue={(val: any) => {
                        setValue('nameWithTranslation', val);
                      }}
                      name='nameWithTranslation'
                      required
                      error={t(errors.nameWithTranslation?.message)}
                      variant='outline'
                      className='mb-5 '
                    />
                    <Input
                      label={`${t('input-product-code')}`}
                      required
                      {...register('productCode')}
                      variant='outline'
                      type='number'
                      error={t(errors.productCode?.message)}
                      containerClassName='w-full md:w-1/2 mb-4 mt-1 md:mb-0'
                    />
                  </div>

                  <div className='mt-4 w-full flex-1 svelte-1l8159u '>
                    <Label> {t('input-label-variant-price')} </Label>
                    <div className='flex flex-col-2'>
                      <Input
                        label={`${t('input-label-price')}`}
                        {...register('price')}
                        variant='outline'
                        error={t(errors.price?.message)}
                        defaultValue={getValues('price')}
                        containerClassName='ml-6'
                      />
                      <Input
                        label={t('input-label-sale-price')}
                        {...register('discountPrice')}
                        error={t(errors.discountPrice?.message)}
                        defaultValue={
                          getValues('discountPrice') === 0
                            ? ''
                            : getValues('price') - getValues('discountPrice')
                        }
                        variant='outline'
                      />
                    </div>
                    <div className=' mt-2'>
                      <Label className='mt-3'>{t('input-label-sales-expiration-date-text')}</Label>
                      <DatePicker
                        dateFormat='dd/MM/yyyy'
                        onChange={(date: Date) => onChangeDate(date)}
                        selected={date ? new Date(date) : null}
                        minDate={new Date()}
                      />
                      <>
                        {errors.endDiscountDate?.message && (
                          <span className='my-2 text-xs text-end text-red-500'>
                            {t(errors.endDiscountDate?.message)}
                          </span>
                        )}
                      </>
                      {_isProductHasSaleDiscount && (
                        <div className='my-2 text-xs text-start text-blue-500'>
                          <FontAwesomeIcon icon={faCircleInfo as IconProp} />
                          <span className='font-bold'>{` ${t('product-have-discount')}`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='mt-4'>
                    <UnControlledFileInput
                      classContainer='w-full'
                      title={t('product-gallery-image')}
                      defaultValue={getValues('mainImage')}
                      multiple={false}
                      withCrop
                      croppedMaxHeight={120}
                      croppedMaxWidth={80}
                      onImageUploaded={(val) => setValue('mainImage', val)}
                      uploadSection={UPLOAD_SECTION.PRODUCTS}
                    />
                  </div>
                </div>
              </div>
              <div className='pt-2'>
                <div className='w-full flex-1 svelte-1l8159u '>
                  <div className='w-1/2 '>
                    <Label>{t('input-label-variants-type')}</Label>
                    <UnControlledSelectInput
                      handleOnChange={(val) => {
                        handleVariantId(val);
                      }}
                      idKey='optionValueId'
                      defaultValue={getVariantTypeValue()}
                      getOptionLabel={(option: any) => option.optionValue}
                      getOptionValue={(option: any) => option.optionValueId}
                      options={getVariantIds()}
                      value={getVariantTypeValue()}
                    />
                  </div>
                  {variantTypeId === 'k4' ||
                    (variantTypeId === 'k5' && (
                      <div className='w-full flex flex-row smx:flex-col mt-2'>
                        <UnControlledFileInput
                          classContainer='md:w-1/2 ml-6'
                          title={t('product-variant-meta')}
                          defaultValue={getValues('variantMeta')}
                          multiple={false}
                          withCrop
                          croppedMaxHeight={120}
                          croppedMaxWidth={80}
                          onImageUploaded={(val) => handleMetaAddImage(val)}
                          uploadSection={UPLOAD_SECTION.PRODUCTS}
                        />
                        <InputWithTranslation
                          label={`${t('input-label-meta-data')}`}
                          type='string'
                          name={'variantMetaText'}
                          values={getValues('variantMetaText')}
                          setValue={(val: any) => setValue('variantMetaText', val)}
                          variant='outline'
                          className='lg:w-2/3 step7prod'
                          containerClassName='ml-6 mt-6'
                        />
                      </div>
                    ))}
                </div>
              </div>
            </>
            <Button type='submit' className='ml-5 mt-5'>
              {t('button-label-save')}
            </Button>
            <Button variant='outline' onClick={() => closeModal()}>
              {t('button-label-back')}
            </Button>
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};

export default VariantComponent;
