import _ from 'lodash';
import { format } from 'date-fns';
import Cookie from 'js-cookie';
import { LOCALE } from './utils/constants';
import {
  Product,
  ProductInfoWithTranslations,
  TranslationObject,
  VariantDetailsList,
} from './ts-types/custom.types';
import moment from 'moment';
import { getIsraelDate } from './utils/productUtils';

const LANGUAGES = ['he', 'ar'];

export const gteImageUrl = (imageUrl = '', defaultImage: string) => {
  let image = _.isEmpty(imageUrl) ? defaultImage : imageUrl;
  if (typeof image === 'string') {
    image = image.replace(
      'https://firebasestorage.googleapis.com',
      'https://ik.imagekit.io/bcupc7szar',
    );
  }
  return image;
};

export const formatDate = (dateToFormat: string) => {
  if (dateToFormat && dateToFormat !== '') {
    // eslint-disable-next-line quotes
    return format(Date.parse(dateToFormat), "uuuu-MM-dd'T'HH:mmXXXXX");
  }

  // eslint-disable-next-line quotes
  return format(new Date(), "uuuu-MM-dd'T'HH:mmXXXXX");
};

export const isValidDiscountDate = (date?: string) => {
  const serverDate = getIsraelDate();
  if (date && serverDate) {
    const endDateMoment = moment.utc(date).format('YYYY-MM-DD'); // Force UTC
    const serverDateMoment = moment.utc(serverDate).format('YYYY-MM-DD'); // Force UTC

    if (moment.utc(endDateMoment).isBefore(moment.utc(serverDateMoment))) {
      return false;
    }
    return true;
  }
  return false;
};

export const getFormattedDate = (date?: string) => {
  const formattedDate = date ? new Date(date).getTime() : null;

  const adjustedDate =
    formattedDate !== null
      ? new Date(formattedDate + new Date().getTimezoneOffset() * 60000).getTime()
      : null;
  return adjustedDate;
};

export function setLocale(locale: string, i18n: any) {
  const lang = locale.split('-')[0] ?? 'he';

  const newLocale = LANGUAGES.includes(lang) ? lang : 'he';

  i18n.changeLanguage(newLocale);
  Cookie.set(LOCALE, newLocale);
}

export function getLocale() {
  return Cookie.get(LOCALE);
}

export function getTranslatedItem(obj: TranslationObject[] | undefined): string {
  const locale = getLocale() ?? 'he';

  return obj?.find((item: TranslationObject) => item.Language === locale)?.value ?? '';
}

export const generateRandomString = (length: number) => {
  let result = '';

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getTranslatedValue = (values: TranslationObject[], activeLanguage: string): string => {
  const translatedObject = values?.find(
    (translatedObject: TranslationObject) => translatedObject.Language === activeLanguage,
  );
  return translatedObject?.value || ''; // Return an empty string if no match is found
};

export function getTranslatedProductItem(
  obj: ProductInfoWithTranslations[],
): ProductInfoWithTranslations {
  const locale = getLocale() ?? 'he';

  return obj.find((item: ProductInfoWithTranslations) => item.language === locale) ?? {};
}

export const extractMonthAndYearFormCreditCardField = (
  monthYear: string,
): { month: number; year: number } => {
  const value = { month: 0, year: 0 };

  if (monthYear !== '') {
    value.month = parseInt(monthYear.slice(0, 2), 10);
    value.year = parseInt(monthYear.slice(2, 4), 10);
  }

  return value;
};

export const getTranslatedLang = (values: TranslationObject[], activeLanguage: string) => {
  return !!values?.find((item: TranslationObject) => item.Language === activeLanguage);
};

export const clearUpdateShopPayload = (values: any) => {
  for (const key in values) {
    if (values[key] === '') {
      delete values[key];
    }
  }
  for (const key in values.cardDetails) {
    if (values[key] === '') {
      delete values[key];
    }
  }
  for (const key in values.customerDetails) {
    if (values[key] === '') {
      delete values[key];
    }
  }

  return values;
};

export function variantIsInSock(variant?: VariantDetailsList | Product): boolean {
  if (variant) {
    if (variant?.countInStock) {
      return variant?.countInStock > 0;
    }
  }

  return false;
}

export function getDefaultVariant(
  product: Product,
  placeholderImage: any,
): VariantDetailsList | undefined {
  let variant: VariantDetailsList | undefined;

  if (!_.isEmpty(product)) {
    if (product.variantDetailsList) {
      variant = product.variantDetailsList.find((item) => item.mainVariant);

      if (variant) {
        const { mainImage } = product.variantDetailsList[0];

        variant = product?.variantDetailsList?.[0];

        if (!mainImage) {
          variant.mainImage = product.mainImage ?? placeholderImage;
        }
      } else {
        variant = product.variantDetailsList?.[0];
      }
    }
  }

  return variant;
}

export const getEndOfDayDate = (date: any) => {
  if (date) {
    const endDate = moment.utc(date).endOf('day');
    return endDate.toISOString();
  }
  return '';
};

export const convertToLocalDate = (date: any) => {
  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) {
    // Return a fallback or handle the error case
    return '';
  }
  return format(
    new Date(new Date(date!).getTime() + new Date().getTimezoneOffset() * 60000),
    'dd/MM/yy',
  );
};
