import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UPLOAD_SECTION } from 'src/ts-types';
import {
  AddOnCategory,
  AddOnsCategoriesID,
  Category,
  ImageType,
  VariantDetailsList,
} from 'src/ts-types/custom.types';
import { DatePicker } from 'src/components/ui/date-picker';
import Card from '../common/card';
import UnControlledFileInput from '../ui/unControlled-file-input';
import Input from '../ui/input';
import Label from 'src/components/ui/label';
import UnControlledSelectInput from 'src/components/ui/unControlled-select-input';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { setProductCurrentVariant } from 'src/store/shop/actions';
import ProductTypeInput from './product-type-input';
import { ProductType } from 'src/ts-types/generated';
import { RootState } from 'src/store';
import Joyride from 'react-joyride';
import { formatDate, getTranslatedItem, isValidDiscountDate } from 'src/common';
import Button from '../ui/button';
import { scroller } from 'react-scroll';
import { useModalAction } from '../ui/modal/modal.context';
import { generateGUID, getEndDiscountDate, isProductHasSaleDiscount } from 'src/utils/productUtils';
import ProductVariantList from './productVariantList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  register: any;
  errors: any;
  show: boolean;
  trigger?: any;
  control: any;
  setValue: (name: string, val: unknown) => void;
  updateCurrentProduct: () => void;
  getValues: (val: any) => any;
  watch: (val: any) => any;
  setProductDiscount: (val: any) => void;
}

const ProductVariantInfo = ({
  register,
  errors,
  show,
  control,
  setValue,
  getValues,
  trigger,
  watch,
}: Props) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const { openModal } = useModalAction();
  const activeProduct = useAppSelector((state: RootState) => state.shop.shopDetails?.activeProduct);
  const addons = useAppSelector((state: RootState) => state.shop.shopDetails?.addonsCategories);
  const productVariants =
    useAppSelector((state: RootState) => state.shop.shopDetails?.productVariants) ?? [];

  useEffect(() => {
    scroller.scrollTo('details', {
      smooth: true,
      offset: -80,
    });
  }, []);

  const [date, setDate] = useState<number | null>(() => {
    const categoryEndDiscountDate = activeProduct?.categoryEndDiscountDate;
    const variantDiscountDate = getValues('variantDetailsList[0].endDiscountDate');
    const isVariantDiscountDateValid = isValidDiscountDate(variantDiscountDate);
    const isCategoryDiscountDateValid = isValidDiscountDate(categoryEndDiscountDate);

    let variantEndDiscountDateRaw = null;
    if (isVariantDiscountDateValid) {
      variantEndDiscountDateRaw = variantDiscountDate;
    } else if (isCategoryDiscountDateValid) {
      variantEndDiscountDateRaw = categoryEndDiscountDate;
    }

    const endDiscountDate = variantEndDiscountDateRaw
      ? new Date(variantEndDiscountDateRaw).getTime()
      : null;

    const adjustedDate =
      endDiscountDate !== null
        ? new Date(endDiscountDate + new Date().getTimezoneOffset() * 60000).getTime()
        : null;
    return adjustedDate;
  });

  const [productAddons, setProductAddons] = useState<AddOnsCategoriesID[]>(
    activeProduct?.addOnsCategories ?? [],
  );

  const getProductAddons = () => {
    return productAddons?.map((item) => item.addonCategoryId);
  };

  const handleAddImage = (key: string, values: ImageType[]) => {
    const imageArr: string[] = [];
    values?.forEach((item: ImageType) => imageArr.push(item.imageUrl));
    setValue(key, imageArr);
  };

  const handleAddAddons = (val: any) => {
    const selectedAddons = val.map((item: any) => ({
      addonCategoryId: item.optionValueId,
      minNumberOfAddons: 0,
    }));
    setProductAddons(selectedAddons);
    setValue('addOnsCategories', selectedAddons);
  };

  const handleSetMainImage = (val: string | ImageType[]) => {
    setValue('mainImage', val);
  };

  const onChangeDate = (value: Date) => {
    if (value) {
      const utcDate = new Date(Date.UTC(value?.getFullYear(), value?.getMonth(), value?.getDate()));
      setDate(utcDate?.getTime());
      setValue('endDiscountDate', formatDate(utcDate?.toISOString()));
    } else {
      setDate(null);
      setValue('endDiscountDate', undefined);
    }
  };
  const steps = [
    {
      target: '.step7prod',
      content: t('step7prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step8prod',
      content: t('step8prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step9prod',
      content: t('step9prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step10prod',
      content: t('step10prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step11prod',
      content: t('step11prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step12prod',
      content: t('step12prod'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];

  const addonsToDisplay = (): object[] | Category[] | any[] | undefined => {
    const addonsOptions: any[] = [];
    addons?.data?.map((item: AddOnCategory) => {
      const opt: any = {};
      opt.optionValueId = item.addOnCategoryId;
      opt.optionValue = getTranslatedItem(item.titleWithTeanslations);
      addonsOptions.push(opt);
    });
    addonsOptions?.map((item) => item.addonCategoryId) ?? [];

    return addonsOptions;
  };

  const handleAddVariant = async (e: any) => {
    e.preventDefault();
    const isFormValid = await trigger(['productType']);
    if (isFormValid) {
      const newVariant: VariantDetailsList = {
        id: generateGUID(),
        countInStock: 1000,
        active: true,
        nameWithTranslation: getValues('name'),
        price: getValues('price'),
        discountPrice: getValues('discountPrice'),
        mainVariant: productVariants.length === 0,
      };
      setValue('price', productVariants?.[0]?.price);
      dispatch(setProductCurrentVariant(newVariant));
      openModal('PRODUCT_VARIANT_VIEW', {}, t('variant-form-title'));
    }
  };

  const handleProductTypeChange = (val: any) => {
    setValue('productType', val);
  };

  watch('productType');
  const _isProductHasSaleDiscount = isProductHasSaleDiscount(activeProduct);
  const endDiscountDate = getEndDiscountDate(activeProduct!, undefined);
  const _isValidEndDiscountDate = isValidDiscountDate(endDiscountDate);
  return (
    <div className='mb-100' hidden={!show}>
      {process.env.TOUR_IS_ACTIVE === 'true' && (
        <Joyride
          steps={steps}
          // callback={handleCallback}
          scrollToFirstStep
          showSkipButton
          styles={{
            options: {
              zIndex: 2000000,
            },
          }}
          continuous
          hideCloseButton
          showProgress
        />
      )}
      <Card className='w-full'>
        <div className='lg:grid md:grid-rows-2  lg:grid-rows-1'>
          <div className=''>
            <div className='z-50'>
              <ProductTypeInput
                required
                error={t(errors.productType?.message)}
                className='w-full z-auto step10prod'
                defaultValue={getValues('productType')}
                onChange={(val) => {
                  handleProductTypeChange(val);
                }}
              />
              <div className='w-1/3 flex sm:flex-col-1  md:flex-col-1x§xinput-product-mill md:flex-row'>
                <Input
                  containerClassName='w-1/3'
                  type='number'
                  label={t('input-product-mill')}
                  {...register('productMilli')}
                  variant='outline'
                  error={t(errors.productMilli?.message)}
                  className='lg:w-2/3 step8prod'
                />
              </div>
            </div>
            {getValues('productType') === ProductType.Simple && (
              <>
                <div className=' mt-3'>
                  <Label>{t('input-label-addons')}</Label>
                  <UnControlledSelectInput
                    name={'addOnsCategories'}
                    control={control}
                    isMulti
                    defaultValue={getProductAddons()}
                    handleOnChange={(val) => handleAddAddons(val)}
                    idKey='optionValueId'
                    getOptionLabel={(option: any) => option.optionValue}
                    getOptionValue={(option: any) => option.optionValueId}
                    options={addonsToDisplay()}
                  />
                </div>
                <div className='flex sm:flex-col-2  md:flex-col-2 md:flex-row'>
                  <Input
                    label={`${t('input-label-price')}`}
                    required
                    {...register('price')}
                    error={t(errors.price?.message)}
                    variant='outline'
                    className='lg:w-2/3 step7prod'
                    containerClassName='ml-6'
                  />
                  <Input
                    label={t('input-label-sale-price')}
                    {...register('discountPrice')}
                    variant='outline'
                    error={t(errors.discountPrice?.message)}
                    className='lg:w-2/3 step8prod'
                  />
                </div>
                <div className=' mt-2'>
                  <Label className='mt-3'>{t('input-label-sales-expiration-date-text')}</Label>
                  <DatePicker
                    dateFormat='dd/MM/yyyy'
                    onChange={(date: Date) => onChangeDate(date)}
                    selected={date ? new Date(date) : null}
                    minDate={new Date()}
                  />
                  <>
                    {errors.endDiscountDate?.message && (
                      <span className='my-2 text-xs text-end text-red-500'>
                        {t(errors.endDiscountDate?.message)}
                      </span>
                    )}
                    {!_isValidEndDiscountDate && endDiscountDate !== undefined && (
                      <div className='my-2 text-xs text-start text-yellow-500'>
                        <FontAwesomeIcon icon={faCircleInfo as IconProp} />
                        <span className='font-bold'>{` ${t('product-old-discount')}`}</span>
                      </div>
                    )}
                    {_isProductHasSaleDiscount && (
                      <div className='my-2 text-xs text-start text-blue-500'>
                        <FontAwesomeIcon icon={faCircleInfo as IconProp} />
                        <span className='font-bold'>{` ${t('product-have-discount')}`}</span>
                      </div>
                    )}
                  </>
                </div>
                <div className='flex flex-col md:flex-row pt-[12px]'>
                  <div className='w-full md:w-1/2 mx-2 flex-1 svelte-1l8159u'>
                    <UnControlledFileInput
                      classContainer='w-full step11prod'
                      title={t('product-main-image')}
                      multiple={false}
                      withCrop
                      croppedMaxHeight={120}
                      croppedMaxWidth={80}
                      // @ts-ignore
                      onImageUploaded={(val) => {
                        handleSetMainImage(val);
                      }}
                      defaultValue={getValues('mainImage')}
                      uploadSection={UPLOAD_SECTION.PRODUCTS}
                    />
                  </div>
                  <div className='w-full md:w-1/2 mx-2 flex-1 svelte-1l8159u'>
                    <UnControlledFileInput
                      classContainer='w-full step12prod'
                      title={t('product-gallery-image')}
                      multiple={true}
                      croppedMaxHeight={120}
                      croppedMaxWidth={80}
                      withCrop
                      defaultValue={getValues('moreImages')}
                      // @ts-ignore
                      onImageUploaded={(val: ImageType[]) => {
                        handleAddImage('moreImages', val);
                      }}
                      uploadSection={UPLOAD_SECTION.PRODUCTS}
                    />
                  </div>
                </div>
              </>
            )}
            {getValues('productType') === ProductType.Variable && (
              <>
                <div className='flex justify-end mt-4'>
                  <Button onClick={(e: any) => handleAddVariant(e)}>
                    {t('input-label-add-variant')}
                  </Button>
                </div>
                {/* <VariantComponent register={register} setValue={setValue} getValues={getValues} /> */}
                <ProductVariantList />
              </>
            )}
          </div>
          {/* <Card
            className='p-4 w-3/4 mr-22 step13prod'
            style={{ maxWidth: '320px', maxHeight: '450px' }}
            withShadow={false}
            title={<div className='text-accent font-bold pb-1'>{t('preview-product')}</div>}
          >
            <ProductPreview
              product={activeProduct as Product}
              productLayout={activeShop?.data.productCardType ?? ''}
            />
          </Card> */}
        </div>
      </Card>
    </div>
  );
};

export default ProductVariantInfo;
