import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import Card from '../common/card';
import InputWithTranslation from '../ui/inputWithTranslation';
import TextAreaWithTranslation from '../ui/text-areaWithTranslation';
import ProductCategoryInput from './product-category-input';
import { useEffect, useState } from 'react';
import SwitchInput from '../ui/switch-input';
import { RootState } from 'src/store';
import Joyride from 'react-joyride';
import { updateProduct } from 'src/store/shop/shopsAPIActions';
import Input from '../ui/input';
import { scroller, Element } from 'react-scroll';

interface Props {
  register: any;
  getValues: (val: any) => any;
  errors: any;
  show: boolean;
  control: any;
  onUpdate?: () => void;
  setValue: (name: string, val: unknown) => void;
  watch?: (val: string) => any;
}

const ProductBasicInfo = ({
  errors,
  show,
  control,
  setValue,
  getValues,
  watch,
  register,
  onUpdate,
}: Props) => {
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const activeProduct = useAppSelector((state: RootState) => state.shop.shopDetails?.activeProduct);
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );
  const categories = useAppSelector((state: RootState) => state.shop.shopDetails?.categories);
  const actionType = useAppSelector((state: RootState) => state.shop.shopDetails?.actionType);

  const [isActive, setIsActive] = useState(true);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [isTopProduct, setIsTopProduct] = useState(false);
  const [isHotProduct, setIsHotProduct] = useState(false);

  useEffect(() => {
    scroller.scrollTo('up', {
      smooth: true,
      offset: -80,
    });
  }, []);

  useEffect(() => {
    if (activeProduct) {
      setValue('active', true);
    }
  }, []);

  useEffect(() => {
    if (activeProduct) {
      setIsActive(activeProduct.active ?? false);
      setIsOutOfStock((activeProduct.variantDetailsList?.[0]?.countInStock ?? false) === 0);
      setIsTopProduct(activeProduct.topProduct ?? false);
      setIsHotProduct(activeProduct.hotProduct ?? false);
    }
  }, [activeProduct]);

  const updateStatus = async (status: boolean) => {
    setIsActive(status);
    setValue('active', status);
    if (activeProduct) {
      activeProduct.active = status;
      if (actionType === 'EDIT') {
        await dispatch(updateProduct({ ...activeProduct, active: status }));
      }
    }
  };

  const updateOutOfStock = (status: boolean) => {
    setIsOutOfStock(status);
    setValue('outOfStock', status);
    if (activeProduct) {
      const { variantDetailsList } = activeProduct ?? {};
      if (variantDetailsList && variantDetailsList.length > 0) {
        variantDetailsList[0].countInStock = status ? 0 : 5000;
        if (actionType === 'EDIT') {
          dispatch(updateProduct({ ...activeProduct, variantDetailsList }));
        }
      }
    }
  };

  const updateTopProductStatus = (status: boolean) => {
    setIsTopProduct(status);
    setValue('topProduct', status);
    if (activeProduct) {
      activeProduct.topProduct = status;
      if (actionType === 'EDIT') {
        dispatch(updateProduct({ ...activeProduct, topProduct: status }));
      }
    }
  };

  const updateHotProductStatus = (status: boolean) => {
    setIsHotProduct(status);
    setValue('hotProduct', status);
    if (activeProduct) {
      activeProduct.hotProduct = status;
      if (actionType === 'EDIT') {
        dispatch(updateProduct({ ...activeProduct, hotProduct: status }));
      }
    }
  };

  const steps = [
    {
      target: '.step1prod',
      content: t('step1prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step2prod',
      content: t('step2prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step3prod',
      content: t('step3prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step4prod',
      content: t('step4prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step5prod',
      content: t('step5prod'),
      locale: {
        next: 'הבא', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
    {
      target: '.step6prod',
      content: t('step6prod'),
      locale: {
        last: 'סיום',
        next: 'סיום', // Hebrew for "Next"
        back: 'הקודם',
        skip: 'דלג', // Hebrew for "Skip"
      },
    },
  ];
  [
    {
      target: '.tourSelector',
      content: 'This is the coolest feature on this page',
      title: 'You can have a title here!',
      styles: {
        // this styles override the styles in the props
        options: {
          textColor: 'tomato',
        },
      },
      locale: {
        next: <span>GO GO GO</span>,
        back: <span>BACK BACK</span>,
      },
      placement: 'top',
    },
  ];

  return (
    <div className='mb-100' hidden={!show}>
      <Element name='up'></Element>
      {process.env.TOUR_IS_ACTIVE === 'true' && (
        <Joyride
          steps={steps}
          // callback={handleCallback}
          scrollToFirstStep
          showSkipButton
          styles={{
            options: {
              zIndex: 2000000,
            },
          }}
          continuous
          hideCloseButton
          showProgress
        />
      )}
      <Card className='w-full'>
        <div className='lg:grid md:grid-rows-2 lg:grid-cols-2 lg:grid-rows-1'>
          <div className=''>
            <ProductCategoryInput
              className='w-full step1prod'
              control={control}
              setValue={setValue}
              required
              handleChange={onUpdate}
              categories={categories}
              error={t(errors.categoryId?.message)}
              // @ts-ignore
              defaultValue={getValues('categoryId')}
              watch={watch}
            />
            <div className='flex'>
              <SwitchInput
                label={t('table-item-status')}
                name='active'
                className='pb-2 pl-6'
                onChange={(val) => updateStatus(val)}
                defaultValue={isActive}
                value={isActive}
              />
              <SwitchInput
                label={t('text-out-stock')}
                name='outOfStock'
                className='pb-2'
                onChange={(val) => updateOutOfStock(val)}
                defaultValue={isOutOfStock}
                value={isOutOfStock}
              />
            </div>
            <div className='flex'>
              <SwitchInput
                label={t('input-label-top-product')}
                name='topProduct'
                className='pb-2 pl-6'
                onChange={(val) => updateTopProductStatus(val)}
                defaultValue={isTopProduct}
                value={isTopProduct}
              />
              <SwitchInput
                label={t('hot_product')}
                name='hotProduct'
                className='pb-2'
                onChange={(val) => updateHotProductStatus(val)}
                defaultValue={isHotProduct}
                value={isHotProduct}
              />
            </div>
            <div>
              <Input
                label={`${t('input-label-product-SEO')}`}
                containerClassName='step3prod'
                {...register('productSEO')}
                name='productSEO'
                error={t(errors.productSEO?.message)}
                variant='outline'
                className='mb-5 '
              />
            </div>
            <Input
              label={`${t('input-product-code')}`}
              {...register('productCode')}
              name='productCode'
              required
              error={t(errors.productCode?.message)}
              variant='outline'
              className=''
            />
            <InputWithTranslation
              label={`${t('input-label-name')}`}
              values={getValues('name')}
              containerClassName='step3prod'
              setValue={(val: any) => {
                setValue('name', val);
              }}
              name='name'
              required
              onBlur={onUpdate}
              error={t(errors.name?.message)}
              variant='outline'
              className='mb-5 '
            />
            <TextAreaWithTranslation
              label={`${t('input-label-short-description')}`}
              values={getValues('shortDescription')}
              setValue={(val: any) => {
                setValue('shortDescription', val);
              }}
              onBlur={onUpdate}
              error={t(errors.shortDescription?.message)}
              variant='outline'
              className='mb-5 '
              name='shortDescription'
            />
            <TextAreaWithTranslation
              label={`${t('input-label-description')}`}
              values={getValues('description')}
              onBlur={onUpdate}
              setValue={(val: any) => {
                setValue('description', val);
              }}
              error={t(errors.description?.message)}
              variant='outline'
              className='step5prod'
              name='description'
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ProductBasicInfo;
