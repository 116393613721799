import Card from '../../components/common/card';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { useEffect } from 'react';
import { setActiveSale } from '../../store/shop/actions';
import { Table } from '../../components/ui/table';
import { SaleDetails, SaleSubDetails } from '../../ts-types/custom.types';
import ActionButtons from '../../components/common/action-buttons';
import { SAVE_MODE } from '../../ts-types';
import { ROUTES } from '../../utils/routes';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import Loader from 'src/components/ui/loader/loader';
import { getAllSalesForAmin } from 'src/store/shop/shopsAPIActions';
import _ from 'lodash';
import { withPermission } from 'src/HOC/withPermission';
import { RootState } from 'src/store';
import { siteSettings } from 'src/settings/site.settings';
import { getTranslatedItem } from 'src/common';
import { isValidExpirationDate } from 'src/utils/productUtils';

const SalesPage = () => {
  const { t }: any = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const salesResponse = useAppSelector((state: RootState) => state.shop.shopDetails?.salesResponse);
  const tableData = _.isEmpty(salesResponse?.data) ? [] : salesResponse?.data;

  useEffect(() => {
    dispatch(getAllSalesForAmin());
  }, []);

  const columns = [
    {
      title: t('sales-table-column-description'),
      dataIndex: 'saleDetails',
      key: 'saleDescription',
      align: 'center',
      width: 50,
      render: (saleDetails: SaleSubDetails) => (
        <span className='whitespace-nowrap truncate'>
          {getTranslatedItem(saleDetails?.saleDescription)}
        </span>
      ),
    },
    {
      title: t('sales-table-column-Type'),
      dataIndex: 'saleDetails',
      key: 'saleType',
      align: 'center',
      width: 50,
      render: (saleDetails: any) => (
        <span className='whitespace-nowrap truncate'>
          {t(siteSettings.salesOptions.find((item) => item.value === saleDetails?.saleType)?.label)}
        </span>
      ),
    },
    {
      title: t('sales-table-column-money-to-discount'),
      dataIndex: 'saleDetails',
      key: 'moneyToDiscount',
      align: 'center',
      width: 50,
      render: (saleDetails: any) => (
        <span className='whitespace-nowrap truncate'>{saleDetails?.moneyToDiscount}</span>
      ),
    },
    {
      title: t('sales-table-column-money-to-discount'),
      dataIndex: 'saleDetails',
      key: 'percentageToDiscount',
      align: 'center',
      width: 50,
      render: (saleDetails: any) => (
        <span className='whitespace-nowrap truncate'>{saleDetails?.percentageToDiscount}</span>
      ),
    },
    {
      title: t('sales-table-column-expiration-date'),
      dataIndex: 'saleDetails',
      key: 'moneyToDiscount',
      align: 'center',
      width: 50,
      render: (saleDetails: SaleSubDetails) =>
        !isValidExpirationDate(saleDetails?.expirationDate) ? (
          <span className='whitespace-nowrap truncate text-red-500'>
            {saleDetails?.expirationDate?.substring(0, 10) + ' (expired)'}
          </span>
        ) : (
          <span className='whitespace-nowrap truncate'>
            {saleDetails?.expirationDate?.substring(0, 10)}
          </span>
        ),
    },
    {
      title: t('table-item-actions'),
      dataIndex: 'saleId',
      key: 'saleId',
      align: 'center',
      width: 80,
      render: (saleId: string, record: SaleDetails) => (
        <ActionButtons
          id={saleId || ''}
          editAction={() => {
            dispatch(setActiveSale(record.saleDetails, record.saleId, SAVE_MODE.EDIT)),
              history.push(ROUTES.EDIT_CREATE_SALE);
          }}
          editUrl={ROUTES.EDIT_CREATE_SALE}
          deleteModalView='DELETE_SALE'
        />
      ),
    },
  ];
  const handleCreateNewSale = () => {
    dispatch(
      setActiveSale(
        {
          saleType: '',
          moneyToDiscount: 0,
          percentageToDiscount: 0,
          // eslint-disable-next-line quotes
          expirationDate: format(new Date(), "uuuu-MM-dd'T'HH:mmXXXXX"),
          totalPriceSaleObject: {
            minPriceToGetSale: 0,
          },
          saleId: '',
        },
        '',
        SAVE_MODE.SAVE,
      ),
    );
    history.push(ROUTES.EDIT_CREATE_SALE);
  };

  if (salesResponse?.isFetching) return <Loader text={t('text-loading')} />;

  return (
    <>
      <Card className='flex flex-col mb-8'>
        <div className='w-full flex md:flex-row smx:flex-row items-center'>
          <div className='md:w-1/4 mb-4 md:mb-0'>
            <h1 className='text-lg font-semibold text-heading'>{t('input-label-sales')}</h1>
          </div>

          <div className='justify-end w-full md:w-3/4 flex md:flex-row items-center'>
            <Button onClick={() => handleCreateNewSale()} className='h-12 ms-4 md:ms-6'>
              <span className='hidden md:block'>{t('button-label-add')} +</span>
              <span className='md:hidden'>+ {t('button-label-add')}</span>
            </Button>
          </div>
        </div>
      </Card>
      <div className='rounded overflow-hidden shadow mb-6'>
        <Table
          // @ts-ignore
          columns={columns}
          emptyText={t('empty-table-data')}
          data={tableData}
          rowKey='id'
          scroll={{ x: 900 }}
        />
      </div>
    </>
  );
};

export default withPermission(SalesPage);
