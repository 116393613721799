import React from 'react';
import { useModalAction, useModalState } from './modal/modal.context';
import Button from './button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/utils/reduxHooks';
import { printOrder } from 'src/store/shop/shopsAPIActions';

const PDFViewer: React.FC<any> = () => {
  const modalData = useModalState();
  const { t }: any = useTranslation();
  const { closeModal } = useModalAction();

  const dispatch = useAppDispatch();
  const { data } = modalData;

  const handlePrint = () => {
    dispatch(printOrder(data.orderId, true));
    closeModal();
  };

  return (
    <div className='pdf-viewer-container w-full h-screen'>
      <div className='pdf-toolbar pt-2 pb-2'>
        <Button onClick={handlePrint} className='print-button'>
          {t('send_to_printer')}
        </Button>
      </div>
      <iframe
        className='h-screen'
        src={data.pdfUrl}
        title='PDF Viewer'
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default PDFViewer;
