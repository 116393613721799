import { Product, VariantDetailsList } from 'src/ts-types/custom.types';
import SwitchInput from './switch-input';
import { useAppDispatch, useAppSelector } from 'src/utils/reduxHooks';
import { getAllProducts, updateProduct } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/utils/routes';
import Card from '../common/card';
import usePriceNew from 'src/utils/use-priceNew';
import ActionButtons from '../common/action-buttons';
import { setActiveProduct, setProductVariant } from 'src/store/shop/actions';
import {
  getActiveProduct,
  getOptionValueFromVariants,
  getDiscountPrice,
  getEndDiscountDate,
} from 'src/utils/productUtils';
import { SAVE_MODE } from 'src/ts-types';
import _ from 'lodash';
import ProductCardSkeleton from './productCardSkeleton';
import { useState } from 'react';
import { RootState } from 'src/store';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { convertToLocalDate, getTranslatedProductItem } from 'src/common';

type Props = {
  product: Product;
  data: Product[];
  key: number;
};

const ProductCard = ({ product, key, data }: Props) => {
  const {
    productId,
    moreImages,
    active,
    topProduct,
    hotProduct,
    mainImage,
    variantDetailsList,
    productInfoWithTranslations,
  } = product ?? {};
  const searchTerm = useAppSelector((state: RootState) => state.util.UtilData?.searchTerm);
  const img = _.isEmpty(mainImage) ? variantDetailsList?.[0].mainImage : mainImage;
  const [isLoading, setIsLoading] = useState(false);
  const { t }: any = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const countInStock = product?.variantDetailsList?.[0].countInStock ?? 0;

  const updateStatus = (status: boolean, product: Product) => {
    setIsLoading(true);
    const { page: _page = 1 } = searchTerm ?? {};
    dispatch(updateProduct({ ...product, active: status }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        dispatch(
          getAllProducts({ pageNumber: _page }, searchTerm ?? { categoryId: '', text: '' }, false),
        ).then((res: any) => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setIsLoading(false);
      });
  };

  const updateOutOfStock = (status: boolean, product: Product) => {
    const { variantDetailsList } = product;
    if (variantDetailsList && variantDetailsList.length > 0) {
      variantDetailsList[0].countInStock = status ? 0 : 1000;
      setIsLoading(true);
      const { page: _page = 1 } = searchTerm ?? {};
      dispatch(updateProduct({ ...product, variantDetailsList }))
        .then(() => {
          toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
          dispatch(
            getAllProducts(
              { pageNumber: _page },
              searchTerm ?? { categoryId: '', text: '' },
              false,
            ),
          ).then((res: any) => {
            setIsLoading(false);
          });
        })
        .catch(() => {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
          setIsLoading(false);
        });
    }
  };

  const updateTopProductStatus = (status: boolean, product: Product) => {
    setIsLoading(true);
    const { page: _page = 1 } = searchTerm ?? {};
    dispatch(updateProduct({ ...product, topProduct: status }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        dispatch(
          getAllProducts({ pageNumber: _page }, searchTerm ?? { categoryId: '', text: '' }, false),
        ).then((res: any) => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setIsLoading(false);
      });
  };

  const updateHotProductStatus = (status: boolean, product: Product) => {
    setIsLoading(true);
    const { page: _page = 1 } = searchTerm ?? {};
    dispatch(updateProduct({ ...product, hotProduct: status }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        dispatch(
          getAllProducts({ pageNumber: _page }, searchTerm ?? { categoryId: '', text: '' }, false),
        ).then((res: any) => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setIsLoading(false);
      });
  };

  const handleProductClick = () => {
    dispatch(setActiveProduct(product!, SAVE_MODE.EDIT));

    product?.variantDetailsList?.forEach((item: VariantDetailsList) => {
      const optionValue = getOptionValueFromVariants(
        item?.variantsOptionsCombineList?.[0]?.optionNameId ?? '',
        item?.variantsOptionsCombineList?.[0]?.optionValueId ?? '',
        product,
      );

      if (optionValue) {
        item.variantMetaText = optionValue.valueWithTeanslations;
        item.variantMeta = optionValue.meta;
        item.variantTypeId = item?.variantsOptionsCombineList?.[0]?.optionNameId;
      }
    });

    dispatch(setProductVariant(product?.variantDetailsList));
    history.push(ROUTES.EDIT_PRODUCT);
  };

  const renderHeader = () => {
    return (
      <div className=''>
        <div className='font-bold float-right'>
          <div className='flex'>
            <SwitchInput
              label={t('table-item-status')}
              name='status'
              className='pb-2 pl-6'
              onChange={(val) => updateStatus(val, product)}
              defaultValue={true}
              value={active}
            />
            <SwitchInput
              label={t('text-out-stock')}
              name='outOfStock'
              className='pb-2'
              onChange={(val) => updateOutOfStock(val, product)}
              defaultValue={countInStock === 0}
              value={countInStock === 0}
            />
          </div>
          <div className='flex'>
            <SwitchInput
              label={t('input-label-top-product')}
              name='top'
              className='pb-2 pl-6'
              onChange={(val) => updateTopProductStatus(val, product)}
              defaultValue={topProduct}
              value={topProduct}
            />
            <SwitchInput
              label={t('hot_product')}
              name='hotProduct'
              className='pb-2'
              onChange={(val) => updateHotProductStatus(val, product)}
              defaultValue={hotProduct}
              value={hotProduct}
            />
          </div>
        </div>
        <div
          className='float-left cursor-pointer text-accent font-bold'
          style={{ marginRight: '10px' }}
        >
          <ActionButtons
            className='mt-4'
            id={productId || ''}
            editAction={() => {
              dispatch(setActiveProduct(getActiveProduct(data, productId)!, SAVE_MODE.EDIT));
              history.push(ROUTES.EDIT_PRODUCT);
            }}
            editUrl={ROUTES.EDIT_PRODUCT}
            deleteModalView='DELETE_PRODUCT'
          />
        </div>
      </div>
    );
  };
  const expirationDate = getEndDiscountDate(product, product?.variantDetailsList?.[0]);
  const {
    price,
    basePrice = null,
    discount,
    hasDiscount,
  } = usePriceNew({
    price: product?.price ?? 0,
    discount: getDiscountPrice(product),
    endDiscountDate: expirationDate,
  });

  const formattedDate = hasDiscount ? convertToLocalDate(expirationDate) : '';
  return (
    <>
      {isLoading ? (
        <ProductCardSkeleton />
      ) : (
        <Card title={renderHeader()} key={key}>
          <article key={key} onClick={() => handleProductClick()}>
            <span>
              {`${t('product_bar_code')} : `} <b>{product.productCode}</b>
            </span>
            <div
              className='relative flex items-center justify-center w-auto h-48 sm:h-64'
              role='button'
            >
              {hasDiscount && (
                <div className='z-[0] absolute top-3 left-3 md:top-4 md:left-4 z-10 rounded-full text-xs leading-6 font-semibold px-1.5 sm:px-2 md:px-2.5 bg-yellow-500 text-white'>
                  {discount}
                </div>
              )}
              <img
                className='product-image  max-h-[200px]'
                src={img}
                style={{ objectFit: 'contain' }}
                width={'160px'}
                height={'180px'}
              />
              <div className='pr-2'>
                {moreImages?.map((_img: string, index: number) => {
                  return (
                    <div key={index}>
                      <img className='product-image' src={_img} width={'40px'} height={'60px'} />
                    </div>
                  );
                })}
              </div>
            </div>

            <div className=' relative'>
              <h3 role='button' className='text-heading text-sm font-semibold truncate mb-2'>
                {`${t('table-item-title')} - ${
                  getTranslatedProductItem(productInfoWithTranslations ?? []).name
                }`}
              </h3>
              <h3 role='button' className='text-heading text-sm font-semibold truncate mb-2'>
                {`${t('input-label-short-description')} - ${
                  getTranslatedProductItem(productInfoWithTranslations ?? []).shortDescription
                }`}
              </h3>

              <div className='flex items-center justify-between min-h-6 relative'>
                <div className='relative'>
                  {hasDiscount && (
                    <div className='pb-4'>
                      <del className='text-xs text-muted text-opacity-75 absolute italic'>
                        {basePrice}
                      </del>
                    </div>
                  )}
                  {hasDiscount && (
                    <div className='pb-4'>
                      <div id='product-discount-end-date' className='text-sm text-muted'>
                        {`${t('price-end-date')} `}
                        <span className='font-semibold'>{formattedDate}</span>
                      </div>
                    </div>
                  )}
                  <span className='text-skin-primary text-sm md:text-base font-semibold'>
                    {price}
                  </span>
                </div>
              </div>
            </div>
          </article>
        </Card>
      )}
    </>
  );
};

export default ProductCard;
