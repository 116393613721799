/* eslint-disable react/display-name */
import cn from 'classnames';
import React, { InputHTMLAttributes } from 'react';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  inputClassName?: string;
  required?: boolean;
  containerClassName?: string;
  onChange?: (val: any) => void;
  label?: string;
  note?: string;
  icon?: any;
  name: string;
  labelClass?: string;
  error?: string;
  type?: string;
  shadow?: boolean;
  variant?: 'normal' | 'solid' | 'outline';
  size?: any | 'small' | 'medium' | 'large';
}

const classes = {
  root: 'px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0',
  normal: 'bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent',
  solid: 'bg-gray-100 border border-border-100 focus:bg-light focus:border-accent',
  outline: 'border border-border-base focus:border-accent',
  shadow: 'focus:shadow',
  small: 'md:w-1/3',
  medium: 'md:w-2/3',
  large: 'md:w-3/3',
};

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      containerClassName = '',
      size = 'large',
      label,
      icon,
      note,
      name,
      error,
      children,
      onChange,
      required,
      variant = 'normal',
      shadow = false,
      type = 'text',
      labelClass = '',
      inputClassName,
      ...rest
    },
    ref,
  ) => {
    const rootClassName = cn(
      classes.root,
      {
        [classes.normal]: variant === 'normal',
        [classes.solid]: variant === 'solid',
        [classes.outline]: variant === 'outline',
      },
      {
        [classes.shadow]: shadow,
      },
      {
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
      },
      inputClassName,
      className,
      error ? 'border-red-500 focus:border-red-500' : '', // Add red border if there's an error
    );

    return (
      <div className={`flex-1 svelte-1l8159u ${containerClassName}`}>
        <div className='flex items-center justify-between mb-2 mt-3'>
          <label
            htmlFor={name}
            className={`${
              required ? 'required-input' : ''
            }  text-body-dark font-semibold text-sm leading-none`}
          >
            {label}
          </label>
        </div>
        <div className='relative h-12 inline-block items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0'>
          <div className='absolute top-4 left-3'>{icon}</div>
          <input
            id={name}
            key={name}
            name={name}
            type={type}
            ref={ref}
            className={`p-1 px-2 appearance-none outline-none w-full 
            text-gray-800 focus:outline-none focus:ring-0 ${rootClassName}`}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            aria-invalid={error ? 'true' : 'false'}
            onChange={onChange}
            {...rest}
          />
          {error && (
            <div className='my-2 text-xs text-start text-red-500 w-full md:w-3/4 flex flex-col md:flex-row '>
              {error}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default Input;
