// @ts-nocheck
import { ProductType } from 'src/ts-types/generated';
import * as yup from 'yup';

export const productValidationSchema = yup.object().shape({
  name: yup
    .array()
    .min(1, 'required')
    .of(
      yup.object().shape({
        value: yup.string(),
        Language: yup.string().required('required'),
      }),
    ),
  categoryId: yup.string().required('required'),

  productType: yup.string().required('required'),

  unit: yup.mixed().when('productType', {
    is: (productType: { name: string; value: string; [key: string]: unknown }) =>
      productType?.value === ProductType.Simple,
    then: yup
      .number()
      .typeError('error-quantity-must-number')
      .positive('error-quantity-must-positive')
      .integer('error-quantity-must-integer')
      .required('error-quantity-required'),
  }),

  productCode: yup
    .mixed()
    .test('is-valid-number', 'error-number-required', (value) => {
      return value !== null && value !== undefined && value !== '' && !isNaN(Number(value));
    })
    .required('required'),

  price: yup.number().when('productType', {
    is: 'simple', // Apply validation only when productType is 'simple'
    then: yup
      .number()
      .typeError('error-price-must-number') // Error if price is not a number
      .positive('error-price-must-positive') // Ensure the price is positive
      .required('error-price-required'), // Price is required
    otherwise: yup.number().nullable(), // Otherwise, no validation
  }),
  discountPrice: yup
    .number()
    .transform((value, originalValue) => {
      // Handle empty strings and invalid inputs
      if (originalValue?.trim?.() === '') {
        return null; // Allow nullable for empty fields
      }
      return isNaN(originalValue) ? NaN : value; // Ensure invalid numbers remain invalid
    })
    .typeError('error-discount-price-must-number') // Show error for non-numeric input
    .nullable() // Allow empty field
    .test({
      name: 'discountPrice',
      message: 'מחיר מבצע לא תקין', // Custom error message for discount validation
      test: function (value) {
        const { price } = this.parent;
        if (value != null && price != null) {
          return price - value > 0; // Ensure discountPrice is less than price
        }
        return true; // If there's no discountPrice, the test passes
      },
    }),

  endDiscountDate: yup
    .date()
    .nullable() // Allow `null` for cases where it's not required
    .when('discountPrice', {
      is: (discountPrice) => discountPrice != null, // Only validate if discountPrice is present
      then: yup
        .date()
        .typeError('sales-date') // Handle invalid date format
        .test(
          'is-today-or-later',
          'sales-date', // Error message
          (value) => {
            if (!value) return false; // Ensure the date is not null
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Normalize to the start of today
            return value >= today; // Allow today or later
          },
        )
        .required('require'), // Mark as required
      otherwise: yup.date().nullable(), // No validation when discountPrice is not present
    }),

  status: yup.mixed().when('productType', {
    is: (productType: { name: string; value: string; [key: string]: unknown }) =>
      productType?.value === ProductType.Simple,
    then: yup.mixed().when('productType', {
      is: (productType: { name: string; value: string; [key: string]: unknown }) =>
        productType?.value === ProductType.Simple,
      then: yup.bool().required('error-status-required'),
    }),
  }),

  // variation_options: yup.array().of(
  //   yup.object().shape({
  //     quantity: yup
  //       .number()
  //       .typeError('error-quantity-must-number')
  //       .positive('error-quantity-must-positive')
  //       .integer('error-quantity-must-integer')
  //       .required('error-quantity-required'),
  //     sku: yup.string().required('error-sku-required'),
  //   }),
  // ),
});
