import { useMemo } from 'react';
import { useSettings } from 'src/contexts/settings.context';
import { isValidExpirationDate } from './productUtils';

export function formatPrice({
  price = 0,
  quantity = 1,
  currencyCode = 'ILS',
  locale = 'he',
  displaySign = false,
  signToDisplay = '',
}: {
  price: number;
  quantity?: number;
  currencyCode?: string;
  locale?: string;
  displaySign?: boolean;
  signToDisplay?: string;
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
  const sign = ` / ${signToDisplay}`;
  return (
    <div>
      <span className='font-bold '>{formatCurrency.format(price * quantity)}</span>
      {displaySign && <span className='text-sm'>{sign}</span>}
    </div>
  );
}

export function formatDiscountPrice({
  price,
  discount = 0,
  unitPriceAfterDiscount = price - discount,
  unitPriceBeforeDiscount = price,
  quantity = 1,
  currencyCode,
  locale,
}: {
  price: number;
  discount?: number;
  unitPriceAfterDiscount?: number;
  unitPriceBeforeDiscount?: number;
  quantity: number;
  currencyCode?: string;
  locale: string;
}) {
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const newDiscount = formatDiscount.format(discount / unitPriceBeforeDiscount);

  const discountPrice = formatPrice({
    price: unitPriceAfterDiscount,
    quantity,
    currencyCode,
    locale,
  });
  const unitPrice = formatPrice({
    price: unitPriceAfterDiscount,
    quantity: quantity,
    currencyCode,
    locale,
  });
  const basePrice = formatPrice({
    price: unitPriceBeforeDiscount,
    quantity: quantity,
    currencyCode,
    locale,
  });

  return {
    price: discountPrice,
    unitPrice: unitPrice,
    basePrice: basePrice,
    discount: newDiscount,
    hasDiscount: discount > 0,
  };
}

export default function usePriceNew(data: {
  price: number;
  discount?: number;
  unitPriceAfterDiscount?: number;
  unitPriceBeforeDiscount?: number;
  quantity?: number;
  currencyCode?: string;
  displaySign?: boolean;
  unitPrice?: number;
  endDiscountDate?: string;
}) {
  const { currency = 'ILS' } = useSettings();
  const {
    price,
    discount = 0,
    unitPriceAfterDiscount,
    unitPriceBeforeDiscount,
    quantity = 1,
    displaySign,
    currencyCode = currency,
    endDiscountDate,
  } = data ?? {};
  const locale = 'he';
  const value = useMemo(() => {
    const currentLocale = 'he';
    const isValidEndDiscountDate = isValidExpirationDate(endDiscountDate);

    return discount > 0 && isValidEndDiscountDate
      ? formatDiscountPrice({
          price,
          discount,
          unitPriceAfterDiscount,
          unitPriceBeforeDiscount,
          quantity,
          currencyCode,
          locale: currentLocale,
        })
      : {
          price: formatPrice({
            price,
            quantity,
            currencyCode,
            locale: currentLocale,
          }),
          displaySign,
          basePrice: price,
          hasDiscount: false,
          unitPrice: price,
          discount: discount,
        };
  }, [
    locale,
    discount,
    price,
    unitPriceAfterDiscount,
    unitPriceBeforeDiscount,
    quantity,
    currencyCode,
  ]);

  return value;
}
