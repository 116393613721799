import React from 'react';
import { CustomerDetails } from 'src/ts-types/custom.types';

function removeFalsy(obj: any) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>,
  );
}

export function formatContact(address: CustomerDetails) {
  if (!address) return null;

  const temp = ['customerName', 'emailAddress', 'phone'].reduce(
    (acc, key) => {
      const value = (address as any)[key];
      if (key === 'phone' && value) {
        return {
          ...acc,
          [key]: (
            <a key='phone' href={`tel:${value}`} className='text-blue-500 underline'>
              {value}
            </a>
          ),
        };
      }
      return { ...acc, [key]: value };
    },
    {} as Record<string, React.ReactNode>,
  );

  const formattedContact = removeFalsy(temp);

  return (
    <>
      {Object.entries(formattedContact).map(([key, value], index) => (
        <span key={key} className='contact-item'>
          {index > 0 && ', '}
          {value}
        </span>
      ))}
    </>
  );
}
