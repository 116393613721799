import CategoryDeleteView from 'src/components/category/category-delete-view';
import ProductDeleteView from 'src/components/product/product-delete-view';
import SaleDeleteView from 'src/components/sale/saleDeleteView';

import ShopDeleteView from 'src/components/shop/shopDeleteView';
// import ProductVariantView from 'src/components/product/productVariantView';
import Modal from 'src/components/ui/modal/modal';
import { useModalAction, useModalState } from './modal.context';
import BusinessDetailsModal from 'src/components/dashboardComp/businessDetailsModal';
import SiteSettingsModal from 'src/components/shop/siteSettingsModal';
import PackageDetailsModal from 'src/components/packages/packageDetailsModal';
import CouponDeleteView from 'src/components/coupon/couponDeleteView';
import PluginDeleteView from 'src/components/plugins/deletePluginView';
import ExportImportView from 'src/components/product/import-export-modal';
import EmployeeDeleteView from 'src/components/shopEmployees/deleteEmployeeView';
import OtpModal from 'src/components/auth/otpModal';
import RefundView from 'src/pages/orders/refundView';
import ProductBrandView from 'src/components/product/product-brand-view';
import VariantComponent from 'src/components/product/variantComponent';
import VariantDeleteView from 'src/components/product/variant-delete-view';
import AddonCategoryDeleteView from 'src/components/categoriesAddons/addonCategory-delete-view';
import PDFViewer from '../pdfViewer';

// import ImageCropper from 'src/components/common/ImageCropper';

// const TagDeleteView = dynamic(() => import("src/components/tag/tag-delete-view"));
// const TaxDeleteView = dynamic(() => import("src/components/tax/tax-delete-view"));
// const BanCustomerView = dynamic(() => import("src/components/user/user-ban-view"));
// const UserWalletPointsAddView = dynamic(
//   () => import("src/components/user/user-wallet-points-add-view")
// );
// const ShippingDeleteView = dynamic(
//   () => import("src/components/shipping/shipping-delete-view")
// );
// const CategoryDeleteView = dynamic(
//   () => import("src/components/category/category-delete-view")
// );
// const CouponDeleteView = dynamic(
//   () => import("src/components/coupon/coupon-delete-view")
// );

// const ProductDeleteView = dynamic(
//   () => import("src/components/product/product-delete-view")
// );
// const TypeDeleteView = dynamic(
//   () => import("src/components/group/group-delete-view")
// );
// const AttributeDeleteView = dynamic(
//   () => import("src/components/attribute/attribute-delete-view")
// );

// const ApproveShopView = dynamic(
//   () => import("src/components/shop/approve-shop-view")
// );
// const DisApproveShopView = dynamic(
//   () => import("src/components/shop/disapprove-shop-view")
// );
// const RemoveStaffView = dynamic(
//   () => import("src/components/shop/staff-delete-view")
// );

// const ExportImportView = dynamic(
//   () => import("src/components/product/import-export-modal")
// );

// const AttributeExportImport = dynamic(
//   () => import("src/components/attribute/attribute-import-export")
// );
// const UpdateRefundConfirmationView = dynamic(
//   () => import("src/components/refund/refund-confirmation-view")
// );
// const RefundImageModal = dynamic(
//   () => import("src/components/refund/refund-image-modal")
// );

const ManagedModal = () => {
  const { isOpen, view, title, showCloseButton } = useModalState();
  const { closeModal } = useModalAction();

  // Add logic to define the size for each modal view
  const getSizeForView = (view?: string) => {
    switch (view) {
      case 'PRODUCT_VARIANT_VIEW':
        return 'lg'; // Large modal for product variants
      case 'SITE_SETTINGS_MODAL':
        return 'xl'; // Extra large for site settings
      case 'PACKAGE_DETAILS_MODAL':
        return 'md'; // Medium for package details
      case 'DELETE_PRODUCT':
        return 'sm'; // Small for delete modals
      default:
        return 'md'; // Default medium size for other modals
    }
  };

  const modalSize = getSizeForView(view);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title={title}
      showCloseButton={showCloseButton}
      size={modalSize}
    >
      {view === 'DELETE_PRODUCT' && <ProductDeleteView />}
      {view === 'DELETE_VARIANT' && <VariantDeleteView />}
      {view === 'DELETE_ADDONS_CATEGORY' && <AddonCategoryDeleteView />}
      {view === 'DELETE_BRAND' && <ProductBrandView />}
      {view === 'DELETE_COUPON' && <CouponDeleteView />}
      {view === 'DELETE_SALE' && <SaleDeleteView />}
      {view === 'DELETE_SHOP' && <ShopDeleteView />}
      {view === 'PRODUCT_VARIANT_VIEW' && <VariantComponent />}
      {view === 'PACKAGE_DETAILS_MODAL' && <PackageDetailsModal />}
      {view === 'SITE_SETTINGS_MODAL' && <SiteSettingsModal />}
      {view === 'EXPORT_IMPORT_PRODUCT' && <ExportImportView />}
      {view === 'DELETE_ATTRIBUTE' && <></>}
      {view === 'DELETE_CATEGORY' && <CategoryDeleteView />}
      {view === 'DELETE_EMPLOYEE' && <EmployeeDeleteView />}
      {view === 'OTP_MODAL' && <OtpModal />}
      {view === 'DELETE_PLUGIN' && <PluginDeleteView />}
      {view === 'REFUND' && <RefundView />}
      {view === 'PDF_VIEW' && <PDFViewer />}
      {view === 'DELETE_TAX' && <></>}
      {view === 'DELETE_SHIPPING' && <></>}
    </Modal>
  );
};

export default ManagedModal;
