import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { shopValidationSchema } from './shop-validation-schema';
import { useAppDispatch } from 'src/utils/reduxHooks';
import Stepper from 'src/components/stepper';
import { BalanceInput, ShopSettings, UserAddressInput } from 'src/ts-types/generated';
import { getIcon } from 'src/utils/get-icon';
import * as socialIcons from 'src/components/icons/social';
import { CompanyDetails, CustomerDetails, ShopDetailsType } from 'src/ts-types/custom.types';
import { LANGUAGE } from 'src/ts-types/index';
import { useHistory } from 'react-router-dom';
import ShopBasicInfo from './shopBasicInfo';
import ShopCompanyInfo from './shopCompanyInfo';
import ShopSettingsInfo from './ShopSettingsInfo';
import ShopOwnerInfo from './shopOwnerInfo';
import ShopLayoutInfo from './shopLayoutInfo';
import ShopProductLayoutInfo from './shopProductLayoutInfo';
import { useEffect, useState } from 'react';
import { getShopDetails } from 'src/utils/shopUtils';
import { useTranslation } from 'react-i18next';

const socialIcon = [
  {
    value: 'FacebookIcon',
    label: 'Facebook',
  },
  {
    value: 'InstagramIcon',
    label: 'Instagram',
  },
  {
    value: 'TwitterIcon',
    label: 'Twitter',
  },
  {
    value: 'YouTubeIcon',
    label: 'Youtube',
  },
];

export const updatedIcons = socialIcon.map((item: any) => {
  item.label = (
    <div className='flex space-s-4 items-center text-body'>
      <span className='flex w-4 h-4 items-center justify-center'>
        {getIcon({
          iconList: socialIcons,
          iconName: item.value,
          className: 'w-4 h-4',
        })}
      </span>
      <span>{item.label}</span>
    </div>
  );
  return item;
});

type FormValues = {
  name: string;
  shopDescription: string;
  siteMainImage: any;
  layoutType: string;
  productCardType: string;
  logoUrl: any;
  balance: BalanceInput;
  address: UserAddressInput;
  settings: ShopSettings;
  siteTitle: string;
  siteSubtitle: string;
  customerDetails: CustomerDetails;
  companyDetails: CompanyDetails;
  website?: string;
};

const ShopForm = ({
  initialValues,
  createPage = false,
  language = LANGUAGE.HEBREW,
}: {
  initialValues?: ShopDetailsType;
  createPage: boolean;
  language?: string;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { t }: any = useTranslation<any>();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    control,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onBlur',
    ...(initialValues
      ? {
          defaultValues: {
            ...initialValues,
            siteTitle: initialValues?.siteTitle && initialValues?.siteTitle?.[0]?.value,
            siteSubtitle: initialValues?.siteSubtitle && initialValues?.siteSubtitle?.[0]?.value,
            shopDescription:
              initialValues?.shopDescription && initialValues?.shopDescription?.[0].value,
          },
        }
      : {}),
    resolver: yupResolver(shopValidationSchema),
  });
  useEffect(() => {
    if (initialValues) {
      const { layoutType = null, productCardType = null } = initialValues!;
      setValue('layoutType', layoutType || 'classic');
      setValue('productCardType', productCardType || 'helium');
    } else {
      setValue('layoutType', 'classic');
      setValue('productCardType', 'helium');
    }
  }, [initialValues, setValue]);

  function onSubmit(values: FormValues) {
    const siteTitle: any = [];
    const siteSubtitle: any = [];
    const shopDescription: any = [];
    siteTitle.push({ value: values.siteTitle, Language: 'he' });
    siteSubtitle.push({ value: values.siteSubtitle, Language: 'he' });
    shopDescription.push({ value: values.shopDescription, Language: 'he' });
    const { shopId } = getShopDetails();
    // dispatch(
    //   updateShopDetails(
    //     clearUpdateShopPayload({
    //       ...values,
    //       shopId,
    //       siteTitle,
    //       shopDescription,
    //       siteSubtitle,
    //     }),
    //   ),
    // )
    //   .then(() => {
    //     toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
    //     history.push(`${ROUTES.SHOPS}`);
    //   })
    //   .catch(() => {
    //     toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
    //   });
  }

  function validateCustomerDetails(): Promise<boolean> {
    return new Promise((resolve) => {
      trigger('customerDetails').then((res) => resolve(res));
    });
  }
  function validateCompanyDetails(): Promise<boolean> {
    return new Promise((resolve) => {
      trigger('companyDetails').then((res) => resolve(res));
    });
  }
  function validateShopDetails(): Promise<boolean> {
    return new Promise((resolve) => {
      trigger(['shopDescription', 'siteSubtitle', 'siteTitle']).then((res) => resolve(res));
    });
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stepper
        updateSelectedStep={(val) => setCurrentStep(val)}
        submitButtonText={initialValues && 'update'}
        steps={[
          {
            element: () => (
              <ShopOwnerInfo
                show={0 === currentStep}
                register={register}
                errors={errors}
                getValues={getValues}
              />
            ),
            isNextDisabled: async () => await validateCustomerDetails(),
          },
          {
            element: () => (
              <ShopCompanyInfo
                show={1 === currentStep}
                register={register}
                errors={errors}
                // @ts-ignore
                setValue={setValue}
              />
            ),
            isNextDisabled: async () => validateCompanyDetails(),
          },
          {
            element: () => (
              <ShopSettingsInfo show={2 === currentStep} register={register} errors={errors} />
            ),
            isNextDisabled: async () => await validateShopDetails(),
          },
          {
            element: () => (
              <ShopLayoutInfo show={3 === currentStep} register={register} errors={errors} />
            ),
            isNextDisabled: () => true,
          },
          {
            element: () => (
              <ShopProductLayoutInfo show={4 === currentStep} register={register} errors={errors} />
            ),
            isNextDisabled: () => true,
          },
          {
            element: () => (
              <ShopBasicInfo
                show={5 === currentStep}
                register={register}
                errors={errors}
                control={control}
                // @ts-ignore
                setValue={setValue}
                getValues={getValues}
              />
            ),
            isNextDisabled: () => isValid,
            hasSubmitButton: true,
          },
        ]}
      />
    </form>
  );
};

export default ShopForm;
