import { Attribute } from 'src/ts-types/custom.types';

export const ATTRIBUTES_NAMES = [
  {
    optionName: 'צבע',
    name: 'צבע',
    optionNameId: 'k1',
    optionValue: 'צבע',
    meta: 'color',
  },
  {
    optionName: 'מידה',
    name: 'מידה',
    optionNameId: 'k2',
    optionValue: 'מידה',
  },
];
export const ATTRIBUTES: Attribute[] = [
  {
    optionName: 'צבע',
    name: 'צבע',
    optionNameId: 'k1',
    meta: 'color',
    values: [
      {
        name: 'אדום',
        optionValue: 'Red',
        optionValueId: 'v1',
        meta: '#FF0000',
      },
      {
        name: 'white',
        optionValue: 'white',
        optionValueId: 'v2',
        meta: '#FFFFFF',
      },
      {
        name: 'black',
        optionValue: 'black',
        optionValueId: 'v3',
        meta: '#000000',
      },
      {
        name: 'Lime',
        optionValue: 'Lime',
        optionValueId: 'v4',
        meta: '#00FF00',
      },
      {
        name: 'Blue',
        optionValue: 'Blue',
        optionValueId: 'v5',
        meta: '#0000FF',
      },
      {
        name: 'Yellow',
        optionValue: 'Yellow',
        optionValueId: 'v6',
        meta: '#FFFF00',
      },
      {
        name: 'Cyan',
        optionValue: 'Cyan',
        optionValueId: 'v7',
        meta: '#00FFFF',
      },
      {
        name: 'Magenta',
        optionValue: 'Magenta',
        optionValueId: 'v8',
        meta: '#FF00FF',
      },
      {
        name: 'Silver',
        optionValue: 'Silver',
        optionValueId: 'v9',
        meta: '#C0C0C0',
      },
      {
        name: 'Gray',
        optionValue: 'Gray',
        optionValueId: 'v10',
        meta: '#808080',
      },
      {
        name: 'Maroon',
        optionValue: 'Maroon',
        optionValueId: 'v11',
        meta: '#800000',
      },
      {
        name: 'Olive',
        optionValue: 'Olive',
        optionValueId: 'v12',
        meta: '#808000',
      },
      {
        name: 'Green',
        optionValue: 'Green',
        optionValueId: 'v13',
        meta: '#008000',
      },
      {
        name: 'Purple',
        optionValue: 'Purple',
        optionValueId: 'v14',
        meta: '#800080',
      },
      {
        name: 'Teal',
        optionValue: 'Teal',
        optionValueId: 'v15',
        meta: '#008080',
      },
      {
        name: 'Navy',
        optionValue: 'Navy',
        optionValueId: 'v16',
        meta: '#000080',
      },
    ],
  },
  {
    optionName: ' מידה',
    name: 'מידה',
    optionNameId: 'k2',
    meta: 'size cloths',
    values: [
      {
        meta: 'OneSize',
        optionValue: 'One Size',
        optionValueId: 'v0',
      },
      {
        meta: 'Small',
        optionValue: 'Small',
        optionValueId: 'v1',
      },
      {
        meta: 'Medium',
        optionValue: 'Medium',
        optionValueId: 'v2',
      },
      {
        meta: 'Large',
        optionValue: 'Large',
        optionValueId: 'v3',
      },
      {
        meta: 'X-Large',
        optionValue: 'X-Large',
        optionValueId: 'v4',
      },
      {
        meta: 'XX-Large',
        optionValue: 'XX-Large',
        optionValueId: 'v5',
      },
    ],
  },
  {
    optionName: ' מידה נעלים',
    name: ' מידה נעלים',
    optionNameId: 'k3',
    meta: 'size shoes',
    values: [
      {
        meta: '35',
        optionValue: '35',
        optionValueId: 'v0',
      },
      {
        meta: '36',
        optionValue: '36',
        optionValueId: 'v1',
      },
      {
        meta: '37',
        optionValue: '37',
        optionValueId: 'v2',
      },
      {
        meta: '38',
        optionValue: '38',
        optionValueId: 'v3',
      },
      {
        meta: '39',
        optionValue: '39',
        optionValueId: 'v4',
      },
      {
        meta: '40',
        optionValue: '40',
        optionValueId: 'v5',
      },
      {
        meta: '41',
        optionValue: '41',
        optionValueId: 'v6',
      },
      {
        meta: '42',
        optionValue: '42',
        optionValueId: 'v7',
      },
      {
        meta: '43',
        optionValue: '43',
        optionValueId: 'v8',
      },
      {
        meta: '44',
        optionValue: '44',
        optionValueId: 'v9',
      },
      {
        meta: '45',
        optionValue: '45',
        optionValueId: 'v10',
      },
      {
        meta: '46',
        optionValue: '46',
        optionValueId: 'v11',
      },
      {
        meta: '47',
        optionValue: '47',
        optionValueId: 'v12',
      },
    ],
  },
  {
    optionName: ' גוון',
    name: ' גוון',
    optionNameId: 'k4',
    meta: 'picture',
    values: [
      {
        meta: '',
        optionValue: '',
        optionValueId: 'v0',
      },
    ],
  },
  {
    optionName: ' מ׳ל',
    name: ' מ׳ל',
    optionNameId: 'k5',
    meta: 'mill',
    values: [
      {
        meta: '',
        optionValue: '',
        optionValueId: 'v0',
      },
    ],
  },
];
