import http from './http';

export default class Base<C, U> {
  http = async <T>(url: string, type: string, variables: T | null = null, options?: any) => {
    if (url) {
      return (http as any)[type](url, variables, options);
    }
  };
  all = async (url: string) => {
    if (url) {
      return this.http(url, 'get');
    }
  };

  find = async (url: string) => {
    return this.http(url, 'get');
  };

  print = async (url: string, printOrder: boolean) => {
    const headers = {
      accept: 'application/pdf',
      printOrder: printOrder.toString(),
    };
    return this.http(url, 'get', { headers, responseType: 'arraybuffer' });
  };

  create = async (url: string, variables: C) => {
    if (url) {
      return this.http<C>(url, 'post', variables);
    }
  };

  update = async (url: string, variables: U) => {
    if (url && variables) {
      return this.http<U>(url, 'put', variables);
    }
  };

  delete = async (url: string) => {
    return this.http(url, 'delete');
  };
}
